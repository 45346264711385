import React, { useEffect, useState } from 'react';
import './Dashboard.css';

function Dashboard() {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const mainContent = document.querySelector('.main-content');

    const handleScroll = () => {
      const scrollTop = mainContent.scrollTop;
      console.log('Scroll position:', scrollTop); 
      if (scrollTop > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    if (mainContent) {
      console.log("Adding scroll event listener to main-content");
      mainContent.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (mainContent) {
        console.log("Removing scroll event listener from main-content");
        mainContent.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);
  return (
    <div className="dashboard">
      <header className="dashboard-header">
        <div className="header-content">
          <nav className={`sidebar-nav ${isScrolled ? 'hidden' : ''}`}>
            <ul>
              <li><a href="#overview">Overview</a></li>
              <li><a href="#trading">Trading</a></li>
              <li><a href="#affiliate">Affiliates</a></li>
              <li><a href="#competition">Competitions</a></li>
              <li><a href="#payouts">Payouts</a></li>
              <li><a href="#settings">Settings</a></li>
            </ul>
          </nav>
          <button className="logout-button">Log Out</button>
        </div>
      </header>
      <main className="main-content">
          <section className="statistics-overview">
            <h2>Statistics Overview</h2>
            <div className="statistics-placeholder">[Graph Placeholder]</div>
          </section>
          <section className="financial-overview">
            <div className="overview-item">
              <h3>Today's Profit</h3>
              <p>$18.6K</p>
            </div>
            <div className="overview-item">
              <h3>Drawdown</h3>
              <p>55%</p>
            </div>
            <div className="overview-item">
              <h3>Training Days</h3>
              <p>10</p>
            </div>
            <div className="overview-item">
              <h3>Current Balance</h3>
              <p>$15,000</p>
            </div>
          </section>
          <section className="summary-overview">
            <h2>Summary</h2>
            <div className="summary-placeholder">[Summary Table Placeholder]</div>
          </section>
        </main>
    </div>
  );
}

export default Dashboard;
