import React from 'react';
import { Link } from 'react-router-dom'; 
import './Login.css';
import googleLogo from './images/google.svg';  // Add path to your Google logo
import facebookLogo from './images/facebook.svg'; // Add path to your Facebook logo
import githubLogo from './images/github.svg';  // Add path to your GitHub logo

function Login() {
  return (
    <div className="login-page">
      <header className="login-header">
        <Link to="/">  {/* Wrap LoveFutures text in Link */}
          <h1>LoveFutures</h1>
        </Link>
      </header>
      <div className="login-container">
        <h2>Log in</h2>
        <div className="login-social">
          <button className="social-button google">
            <img src={googleLogo} alt="Google" />
          </button>
          <button className="social-button facebook">
            <img src={facebookLogo} alt="Facebook" />
          </button>
          <button className="social-button github">
            <img src={githubLogo} alt="GitHub" />
          </button>
        </div>
        <div className="divider">or</div>
        <form>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input type="email" id="email" name="email" placeholder="Enter your email" required />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input type="password" id="password" name="password" placeholder="Enter your password" required />
          </div>
          <div className="form-options">
            <div>
              <input type="checkbox" id="remember" />
              <label htmlFor="remember">Remember me</label>
            </div>
          </div>
          <button type="submit" className="login-button">Log in</button>
        </form>
        <div className="login-links">
          <a href="/forgot-password">Forgot password?</a>
          <a href="/cant-access-account">Can't Access Your Account?</a>
        </div>
        <div className="register-link">
          <p>Not a member yet? <a href="/register">Register here</a> and get started now!</p>
        </div>
      </div>
    </div>
  );
}

export default Login;
