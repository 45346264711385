import React from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom'; // Import useParams, Link, useNavigate hooks
import './Register.css';
import creditCardIcon from './images/creditCard.svg';
import paypalIcon from './images/paypal.svg';
import googlePayIcon from './images/googlePay.svg';

function Register() {
  const { plan } = useParams(); // Get the plan from the URL
  const navigate = useNavigate(); // To navigate to different URLs

  // Create an object for plan details based on the selected plan
  const planDetails = {
    '50': { name: '50k Plan', price: '$99/month' },
    '100': { name: '100k Plan', price: '$199/month' },
    '150': { name: '150k Plan', price: '$299/month' }
  };

  // Get the selected plan's details (fallback to the 50k plan if not found)
  const selectedPlan = planDetails[plan];

  const handlePlanSelect = (planId) => {
    navigate(`/register/${planId}`); // Navigate to the selected plan URL
  };

  return (
    <div className="register-page-custom">
      <header className="register-header-custom">
        <Link to="/"> {/* Wrap LoveFutures text in Link */}
          <h1>LoveFutures</h1>
        </Link>
      </header>
      <div className="register-container-custom">

        {/* If no plan is selected, show all plans */}
        {!plan ? (
          <div className="register-choose-plan-custom">
            <h2>Choose a plan to get started</h2>
            <div className="register-plans-custom">
              <div className="plan-custom" onClick={() => handlePlanSelect('50')}>
                <p>50k Plan</p>
                <h3>$99/month</h3>
              </div>
              <div className="plan-custom" onClick={() => handlePlanSelect('100')}>
                <p>100k Plan</p>
                <h3>$199/month</h3>
              </div>
              <div className="plan-custom" onClick={() => handlePlanSelect('150')}>
                <p>150k Plan</p>
                <h3>$299/month</h3>
              </div>
            </div>
          </div>
        ) : (
          <>
            {/* Show selected plan details */}
            <div className="register-deal-custom">
              <h2>You're almost there! Complete your registration</h2>
              <div className="register-plans-custom">
                <div className="plan-custom">
                  <p>{selectedPlan?.name}</p>
                  <h3>{selectedPlan?.price}</h3>
                </div>
                {/* Option to change plan */}
                <button onClick={() => navigate('/register')} className="change-plan-button-custom">
                  Change Plan
                </button>
              </div>
            </div>

            {/* Create account form */}
            <div className="register-steps-custom">
              <h2>1. Create your account</h2>
              <form className="account-form-custom">
                <div className="form-group-custom">
                  <label htmlFor="email">Email</label>
                  <input type="email" id="email" placeholder="Enter your email" required />
                </div>
                <div className="form-group-custom">
                  <label htmlFor="password">Password</label>
                  <input type="password" id="password" placeholder="Enter your password" required />
                </div>
                <div className="form-group-custom">
                  <label htmlFor="confirm-password">Confirm Password</label>
                  <input type="password" id="confirm-password" placeholder="Confirm your password" required />
                </div>
              </form>

              <h2>2. Select payment</h2>
              <div className="payment-options-custom">
                <label>
                  <input type="radio" name="payment" />
                  <img src={creditCardIcon} alt="Credit Card" className="payment-icon" />
                  Credit Card
                </label>
                <label>
                  <input type="radio" name="payment" />
                  <img src={paypalIcon} alt="PayPal" className="payment-icon" />
                  PayPal
                </label>
                <label>
                  <input type="radio" name="payment" />
                  <img src={googlePayIcon} alt="Google Pay" className="payment-icon" />
                  Google Pay
                </label>
              </div>

              <h2>3. Payment details</h2>
              <form className="payment-form-custom">
                <div className="form-group-custom">
                  <label htmlFor="card-name">Name on card</label>
                  <input type="text" id="card-name" placeholder="Enter name on card" required />
                </div>
                <div className="form-group-custom">
                  <label htmlFor="card-number">Card Number</label>
                  <input type="text" id="card-number" placeholder="0000 0000 0000 0000" required />
                </div>
                <div className="form-group-custom">
                  <label htmlFor="exp-date">Expiration Date</label>
                  <input type="text" id="exp-date" placeholder="MM/YY" required />
                </div>
                <div className="form-group-custom">
                  <label htmlFor="cvc">CVC Code</label>
                  <input type="text" id="cvc" placeholder="123" required />
                </div>
              </form>
              <button className="submit-button-custom">Submit Secure Payment</button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default Register;
