import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Dashboard from './Dashboard';
import FAQ from './FAQ';
import Login from './Login';
import Register from './Register';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          {/* Main Home Route */}
          <Route path="/" element={
            <>
              <header className="App-header">
                <nav>
                <ul>
                  <li><a href="/">Home</a></li>
                  <li><a href="#products">Products</a></li>
                  <li><a href="#contact">Contact</a></li>
                  <li><a href="/dashboard">Dashboard</a></li>
                  <li><a href="/faq">FAQ</a></li>
                </ul>
              </nav>
                <div className="header-right">
                  <p>LoveFutures</p>
                  <button className="login-button" onClick={() => window.location.href = '/login'}>Log In</button>
                </div>
              </header>
              <section id="deal">
                <div className="deal-content">
                  <div className="deal-left">
                    <h2>First Time Customer Deal!</h2>
                    <p>Join our prop firm today and enjoy our exclusive offer for first-time customers. Get started with a discounted rate and unlock access to premium trading tools and resources.</p>
                    <ul>
                      <li>Access to a dedicated trading platform with real-time data.</li>
                      <li>Professional support from our team of experienced traders.</li>
                      <li>High-profit targets with minimal risk exposure.</li>
                    </ul>
                    <p className="price">$149/month</p>
                    <button className="claim-button">Claim Deal</button>
                  </div>
                  <div className="deal-right">
                    <div className="placeholder-image">Image Placeholder</div>
                  </div>
                </div>
              </section>

              <section id="reviews">
                <h2>Our Reviews</h2>
                <div className="reviews-container">
                  <div className="review">
                    <p>⭐⭐⭐⭐⭐</p>
                    <p>"LoveFutures has completely transformed my trading experience. The tools and resources available are top-notch, and the support team is always there when I need them."</p>
                    <p>- Karina C.</p>
                  </div>
                  <div className="review">
                    <p>⭐⭐⭐⭐⭐</p>
                    <p>"Thanks to LoveFutures, I’ve been able to achieve consistent profits and grow my portfolio beyond my expectations. Highly recommend for serious traders."</p>
                    <p>- Isabelle D.</p>
                  </div>
                  <div className="review">
                    <p>⭐⭐⭐⭐⭐</p>
                    <p>"The first-time customer deal was the perfect opportunity for me to test the waters. Now, I’m a long-term customer reaping the benefits."</p>
                    <p>- Max T.</p>
                  </div>
                </div>
              </section>

              <section id="plans">
                <h2>Get started with a plan today</h2>
                <p>Choose from our range of plans designed to suit traders at every level. Each plan comes with a 30-day money-back guarantee, ensuring that you can trade with confidence.</p>
                <div className="plans-container">
                  <div className="plan">
                    <h3>50k</h3>
                    <p className="price">$99/month</p>
                    <p className="detail">Intraday Trailing Max Drawdown: $2000</p>
                    <p className="detail">Maximum Contracts: 5</p>
                    <p className="detail">Minimum Trading Days: 10</p>
                    <p className="detail">Scaling: Yes</p>
                    <p className="detail">Max Accounts: 10</p>
                    <button className="select-button" onClick={() => window.location.href = '/register/50'}>Choose Plan</button>
                  </div>
                  <div className="plan most-popular">
                    <h3>100k</h3>
                    <p className="price">$199/month</p>
                    <p className="detail">Intraday Trailing Max Drawdown: $4000</p>
                    <p className="detail">Maximum Contracts: 10</p>
                    <p className="detail">Minimum Trading Days: 10</p>
                    <p className="detail">Scaling: Yes</p>
                    <p className="detail">Max Accounts: 10</p>
                    <button className="select-button" onClick={() => window.location.href = '/register/100'}>Choose Plan</button>
                  </div>
                  <div className="plan">
                    <h3>150k</h3>
                    <p className="price">$299/month</p>
                    <p className="detail">Intraday Trailing Max Drawdown: $6000</p>
                    <p className="detail">Maximum Contracts: 15</p>
                    <p className="detail">Minimum Trading Days: 10</p>
                    <p className="detail">Scaling: Yes</p>
                    <p className="detail">Max Accounts: 10</p>
                    <button className="select-button" onClick={() => window.location.href = '/register/150'}>Choose Plan</button>
                  </div>
                </div>
              </section>

              <section id="dashboard">
                <div className="dashboard-container">
                  <div className="dashboard-left">
                    <div className="dashboard-placeholder">Dashboard Image Placeholder</div>
                  </div>
                  <div className="dashboard-right">
                    <h3>EASY TO USE DASHBOARD</h3>
                    <h2>See everything. Control everything.</h2>
                    <p>
                      Monitor all aspects of your trading performance with our intuitive dashboard. Easily track your portfolio, manage your positions, and stay informed with real-time updates.
                    </p>
                    <p>
                      Designed for both novice and professional traders, our dashboard provides the tools you need to succeed in today's fast-paced markets.
                    </p>
                  </div>
                </div>
              </section>
              
              <section id="contact">
                <div className="contact-container">
                  <div className="contact-left">
                    <h2>24/7 Customer Support</h2>
                    <ul>
                      <li>Reach out to us any time of day for expert support via live chat or email.</li>
                      <li>Our support team is fluent in multiple languages to assist traders from all around the globe.</li>
                      <li>Get quick responses with an average response time of under 5 minutes.</li>
                    </ul>
                  </div>
                  <div className="contact-right">
                    <div className="chat-box">
                      <div className="chat-header">
                        <span>Active</span>
                        <span className="close-btn">×</span>
                      </div>
                      <div className="chat-message">
                        <div className="user-message">
                          <img src="user1-placeholder.png" alt="User 1" />
                          <p>How can I increase my position size?</p>
                        </div>
                        <div className="support-message">
                          <img src="user2-placeholder.png" alt="Support" />
                          <p>You can upgrade your plan or reach the next profit target to unlock higher limits.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <footer className="footer">
                <div className="footer-content">
                  <div className="footer-section">
                    <h3>RESOURCES</h3>
                    <ul>
                      <li><a href="#">Trading Guide</a></li>
                      <li><a href="#">Market Analysis</a></li>
                      <li><a href="#">Help Center</a></li>
                      <li><a href="#">Contact Support</a></li>
                    </ul>
                  </div>
                  <div className="footer-section">
                    <h3>ABOUT</h3>
                    <ul>
                      <li><a href="#">About Us</a></li>
                      <li><a href="#">Our Team</a></li>
                      <li><a href="#">FAQs</a></li>
                      <li><a href="#">Partners</a></li>
                    </ul>
                  </div>
                  <div className="footer-section">
                    <h3>NEWS</h3>
                    <ul>
                      <li><a href="#">Status Updates</a></li>
                      <li><a href="#">Market Trends</a></li>
                      <li><a href="#">Newsletter</a></li>
                    </ul>
                  </div>
                  <div className="footer-section">
                    <h3>EXTRAS</h3>
                    <ul>
                      <li><a href="#">Careers</a></li>
                      <li><a href="#">Community</a></li>
                      <li><a href="#">Affiliates</a></li>
                    </ul>
                  </div>
                  <div className="footer-section newsletter">
                    <h3>Subscribe to our newsletter!</h3>
                    <form>
                      <input type="email" placeholder="Enter your email" />
                      <div className="checkbox">
                        <input type="checkbox" id="terms" />
                        <label htmlFor="terms">
                          I have read and agree to the <a href="#">Terms of Use</a> and <a href="#">Privacy Policy</a>.
                        </label>
                      </div>
                      <button type="submit">SUBMIT</button>
                    </form>
                  </div>
                </div>
                <div className="footer-bottom">
                  <p>&copy; 2024 Love Futures. All rights reserved.</p>
                  <p><a href="#">Privacy Policy</a> | <a href="#">Terms of Use</a> | <a href="#">Risk Disclosure</a></p>
                  <p>Trader Evaluation Disclaimer: The results and performance mentioned on this website are based on simulated or hypothetical trading results that have certain limitations. Unlike the results shown in an actual performance record, these results do not represent actual trading and may not be comparable to those achieved in real trading scenarios.</p>
                </div>
              </footer>
            </>
          } />
          
          {/* Dashboard Route */}
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/faq" element={<FAQ />} /> 
          <Route path="/login" element={<Login />} /> 
          <Route path="/register" element={<Register />} /> 
          <Route path="/register/:plan" element={<Register />} /> {/* <-- Dynamic plan route */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
