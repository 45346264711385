import React from 'react';
import './FAQ.css';  // Keep the same CSS for styling

function FAQ() {
  return (
    <div className="faq-page">
      <header className="App-header">
        <nav>
        <ul>
          <li><a href="/">Home</a></li>
          <li><a href="#products">Products</a></li>
          <li><a href="#contact">Contact</a></li>
          <li><a href="/dashboard">Dashboard</a></li>
        </ul>
      </nav>
        <div className="header-right">
          <p>LoveFutures</p>
          <button className="login-button" onClick={() => window.location.href = '/login'}>Log In</button>
        </div>
      </header>
      <section className="faq-content">
        <div className="faq-item">
          <h3>What are the monthly fees?</h3>
          <p>$99/Month for 50k<br />
            $199/Month for 100k<br />
            $299/Month for 150k</p>
        </div>

        <div className="faq-item">
          <h3>Do you have an Affiliate Program?</h3>
          <p>Yes. The LoveFutures Affiliate Program allows affiliates to earn 10% commission on evaluation and reset fees from traders referred via their unique affiliate link or code.</p>
        </div>

        <div className="faq-item">
          <h3>Does LoveFutures accept traders worldwide?</h3>
          <p>LoveFutures does not accept traders in the following countries:<br />
          Afghanistan, Central African Republic, Congo (Brazzaville), Congo (Kinshasa), Cuba, Guinea-Bissau, Iran, Iraq, North Korea, Libya, Mali, Russian Federation, Somalia, South Sudan, Sudan, Syria, Yemen, Venezuela</p>
        </div>

        <div className="faq-item">
          <h3>What does Simulated Funded Account mean?</h3>
          <p>A Simulated Funded Account means that the account does not require any evaluation or practice prior to trading for profit. When a trader purchases an account, simulated funds are available for use immediately, allowing them to start trading for profit right away!</p>
        </div>

        <div className="faq-item">
          <h3>What is the refund and cancellation policy?</h3>
          <p>LoveFutures does not offer refunds of any kind. We reserve the right to cancel any trading account for violations due to any reasons stated in the terms of use.</p>
        </div>

        <div className="faq-item">
          <h3>What is a reset and how does it work?</h3>
          <p>If you break a rule and fail the challenge, you are not allowed to reset under any circumstances.</p>
        </div>

        <div className="faq-item">
          <h3>Can I hold positions or orders overnight?</h3>
          <p>No. All positions or orders must be closed by 4:59 PM EST Monday through Friday. If any orders are detected and not closed before then, the account will be closed due to a rule violation. Traders can resume trading when the market opens at 6:00 PM EST from Monday through Thursday and on Sunday evening.</p>
        </div>

        <div className="faq-item">
          <h3>How many accounts can I have?</h3>
          <p>Traders can have up to 10 accounts of any size.</p>
        </div>

        <div className="faq-item">
          <h3>Is Copy Trading Allowed?</h3>
          <p>Yes. Copy trading is permitted.</p>
        </div>

        <div className="faq-item">
          <h3>What is the minimum withdrawal amount?</h3>
          <p>The minimum withdrawal amount is $500.</p>
          <p>Withdrawals are processed through our payment system.</p>
        </div>

        <div className="faq-item">
          <h3>What instruments are allowed?</h3>
          <p>Traders are allowed to trade Futures products only, listed on the following exchanges: CME, COMEX, NYMEX & CBOT. Trading of Stocks, Options, Forex, Cryptocurrency, and CFDs are not permitted or available in our program or platforms.</p>
        </div>
      </section>

      <footer className="footer">
        <div className="footer-content">
          <div className="footer-section">
            <h3>RESOURCES</h3>
            <ul>
              <li><a href="#">Trading Guide</a></li>
              <li><a href="#">Market Analysis</a></li>
              <li><a href="#">Help Center</a></li>
              <li><a href="#">Contact Support</a></li>
            </ul>
          </div>
          <div className="footer-section">
            <h3>ABOUT</h3>
            <ul>
              <li><a href="#">About Us</a></li>
              <li><a href="#">Our Team</a></li>
              <li><a href="#">FAQs</a></li>
              <li><a href="#">Partners</a></li>
            </ul>
          </div>
          <div className="footer-section">
            <h3>NEWS</h3>
            <ul>
              <li><a href="#">Status Updates</a></li>
              <li><a href="#">Market Trends</a></li>
              <li><a href="#">Newsletter</a></li>
            </ul>
          </div>
          <div className="footer-section">
            <h3>EXTRAS</h3>
            <ul>
              <li><a href="#">Careers</a></li>
              <li><a href="#">Community</a></li>
              <li><a href="#">Affiliates</a></li>
            </ul>
          </div>
          <div className="footer-section newsletter">
            <h3>Subscribe to our newsletter!</h3>
            <form>
              <input type="email" placeholder="Enter your email" />
              <div className="checkbox">
                <input type="checkbox" id="terms" />
                <label htmlFor="terms">
                  I have read and agree to the <a href="#">Terms of Use</a> and <a href="#">Privacy Policy</a>.
                </label>
              </div>
              <button type="submit">SUBMIT</button>
            </form>
          </div>
        </div>
        <div className="footer-bottom">
          <p>&copy; 2024 Love Futures. All rights reserved.</p>
          <p><a href="#">Privacy Policy</a> | <a href="#">Terms of Use</a> | <a href="#">Risk Disclosure</a></p>
          <p>Trader Evaluation Disclaimer: The results and performance mentioned on this website are based on simulated or hypothetical trading results that have certain limitations. Unlike the results shown in an actual performance record, these results do not represent actual trading and may not be comparable to those achieved in real trading scenarios.</p>
        </div>
      </footer>
    </div>
  );
}

export default FAQ;
